// -------------------------------------------------------------
//   Paragraphs item cta
// -------------------------------------------------------------

.content-cta {
  margin: 30px 0;
  padding: 55px 60px;
  background-color: $red;
  box-shadow: 0px 0px 0px 1px $lightred inset;
  border: 20px solid $red;
  text-align: center;
  h3 {
    margin: 0;
    color: #fff;
    font-weight: bold;
    font-size: 26px;
    margin-bottom: 20px;
  }
  .btn {
    &.btn-border {
      display: inline-block;
      color: #fff;
      padding: 10px;
      font-weight: bold;
      font-size: 16px;
      border: 1px solid $lightred;
      background: $red;
      border-radius: 10px;
      &:hover {
        background: #fff;
        color: $red;
        -webkit-box-shadow: 0px 0px 28px -8px rgba(135,0,0,1) !important;
        -moz-box-shadow: 0px 0px 28px -8px rgba(135,0,0,1) !important;
        box-shadow: 0px 0px 28px -8px rgba(135,0,0,1) !important;
      }
      &.login {
        &:before {
          content: "\f023";
          font-family: FontAwesome;
          margin-right: 10px;
        }
      }
    }
  }
  span.btn-border {
    display: inline-block;
    color: #fff;
    padding: 10px;
    font-weight: bold;
    font-size: 16px;
    border: 1px solid $lightred;
    background: $red;
    border-radius: 10px;
    &.telephone {
      &:before {
        content: "\f095";
        font-family: FontAwesome;
        margin-right: 10px;
      }
    }
  }
  .or {
    display: inline-block;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0 20px;
  }
}