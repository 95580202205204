.galerie-slider {
  width: 100%;
  position: relative;
  margin: 100px auto;
  .swiper-galerie {
    width: 100%;
    overflow: hidden;
    .swiper-wrapper {
      .swiper-slide {
        margin: 0 10px;
        height: 220px;
        position: relative;
        a {
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
        }
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
  .swiper-buttons {
    display: flex;
    justify-content: center;
    .swiper-button-prev {
      background-image: none;
      position: static;
      background-color: $brand-primary;
      width: auto;
      height: auto;
      color: #fff;
      font-size: 1em;
      text-transform: uppercase;
      font-weight: 600;
      padding: 5px 10px;
      -moz-border-radius: 5px;
      -webkit-border-radius: 5px;
      border-radius: 5px;
      margin: 30px 10px 0 10px;
    }
    .swiper-button-next {
      background-image: none;
      position: static;
      background-color: $brand-primary;
      width: auto;
      height: auto;
      color: #fff;
      font-size: 1em;
      text-transform: uppercase;
      font-weight: 600;
      padding: 5px 10px;
      -moz-border-radius: 5px;
      -webkit-border-radius: 5px;
      border-radius: 5px;
      margin: 30px 10px 0 10px;
    }
  }
}