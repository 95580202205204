// -------------------------------------------------------------
//   Main header
//   Header styles
// -------------------------------------------------------------

.main-header {
  background: #fff;
  padding: 20px 0;
  @include transition(padding .2s);
  .logo {
    margin-top: 10px;
    display: block;
    max-width: 180px;
    @include transition(all .2s);
  }
  &.scrolled {
    z-index: 110;
    height: 110px;
    position: fixed;
    width: 100%;
    left: 0;
    top: 0 !important;
    padding: 5px 0;
    background: #fff;
    -webkit-box-shadow: 0px 2px 11px 0px rgba(214,214,214,1);
	-moz-box-shadow: 0px 2px 11px 0px rgba(214,214,214,1);
	box-shadow: 0px 2px 11px 0px rgba(214,214,214,1);
    @include transition(height .2s);
    .logo {
      margin-top: 0;
      max-width: 270px;
      @include transition(max-width .2s);
      img {
        height: 90px;
      }
    }
    .top-line {
      display: none;
    }
    nav#main-nav {

    }
  }
}