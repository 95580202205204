
// Button
// =============================================================================

.btn {
  display: inline-block;
  border: 0;
  border-radius: 35px;
  padding: 18px 25px;
  box-shadow: none !important;
  font-family: $font-family-sans-serif;
  font-size: 0.9rem;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.3em;
  white-space: normal;
  text-decoration: none !important;
  text-transform: uppercase;
  @include transition(all .2s);
  &.btn-primary, &.btn-default, &.btn1 {
    background: $brand-primary;
    text-transform: none;
    font-weight: bold;
    font-size: 14px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    color: #fff;
    &:hover {
      background-color: darken($brand-primary, 15%);
    }
  }
  &.btn2 {
    background-color: transparent !important;
    border: 1px solid $brand-primary;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    padding: 10px 20px;
    color: $brand-primary;
    font-size: .9em;
    text-transform: uppercase;
    .fa {
      position: absolute;
      left: 100%;
      margin-left: 10px;
      font-size: 1rem;
    }
    &:hover {
      background-color: $brand-primary;
      color: #fff;
    }
  }
}
.participation-link {
  background: #000;
  color: #fff;
  border: 0;
  font-size: 14px;
  text-transform: uppercase;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  position: relative;
  padding: 20px;
  text-decoration: none;
  display: inline-block;
  &:before {
    content: "\f078";
    font-family: FontAwesome;
    margin-right: 10px;
    font-size: 10px;
    position: relative;
    top: -2px;
  }
  &:hover, &:focus {
    background: $brand-secondary;
    color: #fff;
    text-decoration: none;
  }
}
input[type="submit"] {
  @extend .btn;
  @extend .btn1;
  cursor: pointer;
}
a.border-button {
  display: inline-block;
  padding: 10px;
  border: 1px solid #000;
  font-family: $font-family-sans-serif;
  color: #000;
  font-size: 16px;
  @include transition(all .2s);
  &:hover {
    text-decoration: none;
    background: #000;
    color: #fff;
  }
}
a.simple-link {
  display: inline-block;
  color: #000;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}
input[type="submit"].form-submit {
  @extend .btn;
  @extend .btn1;
  cursor: pointer;
  font-size: 16px;
  &:hover {
    background: $brand-primary;
    color: #fff;
  }
  &:after {
    content: "\f178";
    font-family: FontAwesome;
    margin-left: 10px;
  }
}