@media (max-width: 1199px) {
  nav#main-nav {
    .menu {
      li {
        a {
          letter-spacing: 0;
          font-size: .85em;
          &:hover {
          }
        }
      }
    }
  }
}
@media (max-width: 991px) {
  nav#main-nav {
    .menu {
      li {
        margin: 0 5px;
      }
    }
  }
  .produits {
    .container-produits {
      ul {
        li {
          width: 50%;
        }
      }
    }
  }
  .clients-slider {
    width: 520px;
  }
  .main-header.scrolled {
    height: auto;
  }
}
@media (max-width: 767px) {
  .mobile-menu {
    display: block;
  }
  .main-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    padding: 0 0 5px 0;
    z-index: 999;
  }
  #main-nav, #secondary-nav {
    display: none;
  }
  main#main {
    margin-top: 100px;
  }
  .produits .container-produits {
    padding: 0;
  }
  .rea-left, .rea-right {
    width: auto;
    float: none;
  }
  .rea-left h2 {
    padding-top: 190px;
    margin: 0;
  }
  .news-list {
    margin-bottom: 20px;
  }
  .clients-slider {
    width: 100%;
    .swiper-clients {
      .swiper-slide {
      }
    }
    .swiper-button-next, .swiper-button-prev {
      display: none;
    }

  }
  section.clients {
    padding-bottom: 40px;
  }
  footer.main-footer {
    padding-top: 10px;
    .footer-box {
      margin-top: 0;
    }
    .logo {
      width: auto;
      float: none;
      margin: 20px 0;
    }
    .address {
      border: 0;
    }
  }
  .main-title {
    padding-top: 50px;
    text-align: center;
    h1 {
      width: 100%;
      float: none;
      padding-bottom: 10px;
    }
    a.devis-page {
      float: none;
      margin: 10px auto;
    }
  }
  .content-container {
    padding: 10px;
    margin-top: -120px;
  }
  #categories {
    ul {
      flex-wrap: wrap;
      justify-content: center;
      li {
        margin: 10px;
      }
    }
  }
  a.produit-list {
    margin: 10px 0;
  }
  h3.product-title {
    margin-top: 20px;
  }
  .produit-fiche {
    ul.nav-tabs {
      display: flex;
      li {
        a {
          padding: 5px;
          text-transform: none;
        }
      }
    }
  }
  section.view-nos-references {
    header {
      padding: 0 0 50px 0;
    }
    .view-filters {
      .views-exposed-widgets {
        display: block;
        .views-exposed-widget {
          .form-type-select {
            width: auto;
            margin-bottom: 10px;
          }
        }
        .views-exposed-widget {
          text-align: center;
        }
      }
    }
    .view-content {
      padding-bottom: 10px;
    }
  }
  .content-evidence {
    .inner-evidence {
      padding: 0 15px;
      border-left: 5px solid $brand-primary;
      .img-left {
        width: auto;
        float: none;
        max-width: 100%;
        margin-bottom: 20px;
      }
    }
  }
  .node-type-webform {
    form {
      margin: 20px;
    }
  }
}
