// -------------------------------------------------------------
//   Main layout
//   Defines basic main styles in layout
// -------------------------------------------------------------
body {
  font-family: $font-family-sans-serif;
}
#main{
  position: relative;
  &.main-scroll {
    margin-top: 80px;
  }
}
footer.footer {

}
h1, .h1 {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 5px;
  font-weight: 300;
}
h2, .h2 {
  font-weight: 300;
  font-size: 3em;
  letter-spacing: 2px;
  color: $brand-secondary;
  margin-bottom: 40px;
  padding-bottom: 20px;
  margin-top: 20px;
  text-transform: uppercase;
  text-align: center;
  position: relative;
  &:after {
    content:'';
    display: block;
    width: 50px;
    height: 1px;
    background: $brand-primary;
    margin: 20px auto 0 auto;
  }
}
h3, .h3 {
  font-size: 1.5em;
  font-weight: 800;
  text-transform: uppercase;
  color: $brand-secondary;
  text-transform: none;
  a {
    color: $brand-secondary;
    &:hover {
      text-decoration: none;
      color: $brand-primary;
    }
  }
}
h4 {
  font-size: 20px;
  font-weight: 500;
  color: $brand-primary;
}
p {
  font-size: 1.2em;
  line-height: 24px;
  color: $middlegrey;
}
a {
  color: $brand-primary;
}
ul {
  margin-top: 20px;
  padding-left: 0;
  li {
    padding: 0 0 10px 0;
    list-style: none;
    color: $middlegrey;
    font-size: 1.2em;
    &:before {
      content: '\f054';
      font-family: Fontawesome;
      font-size: .8em;
      color: $brand-primary;
      margin-right: 5px;
    }
  }
}
.align-right {
  text-align: right;
}
.align-left {
  text-align: left;
}
.modal {
  background: rgba(0,0,0,0.8);
}
.modal-dialog {
  width: 800px;
}
.modal-content {
  border-radius: 0;
  background: transparent;
}
.modal-body {
  background: none;
  padding: 0;
  border-radius: 0;
  text-align: center;
}
.login-box {
  background: $xlightgrey;
  padding: 40px;
  margin-top: 50px;
}
#login {
  padding-bottom: 150px;
}
.main-title {
  background-image: url(../img/bg-main-title.jpg);
  background-size: cover;
  background-position: center;
  height: 325px;
  position: relative;
  padding-top: 70px;
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.5);
  }
  .container {
    position: relative;
    z-index: 2;
  }
  h1, span.h1 {
    display: block;
    width: 50%;
    float: left;
    margin-top: 0;
  }
  a.devis-page {
    color: #fff;
    float: right;
    padding: 10px 20px;
    background: $brand-primary;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    text-transform: uppercase;
    -webkit-box-shadow: 0px 3px 4px -2px rgba(107,107,107,1);
    -moz-box-shadow: 0px 3px 4px -2px rgba(107,107,107,1);
    box-shadow: 0px 3px 4px -2px rgba(107,107,107,1);
    letter-spacing: 2px;
    &:hover {
      text-decoration: none;
      background: $brand-secondary;
    }
    &:after {
      clear: both;
    }
  }
}
.main-content {
  padding-bottom: 150px;
  background: $brand-primary;
}
.content-container {
  background: #fff;
  position: relative;
  margin-top: -150px;
  padding: 30px;
}
.simple-text {
  font-size: 1em;
  color: $middlegrey;
  p {
    font-size: 1em;
  }
}
.evidence {
  background: $xlightgrey;
  padding: 20px;
  color: $darkgrey;
  font-size: 1.3em;
  text-align: center;
}
// Overwrite lightbox2
.lb-cancel {
  background-image: url(../img/loading.gif);
}
.lb-nav {
  a.lb-prev {
    background-image: url(../img/prev.png);
  }
  a.lb-next {
    background-image: url(../img/next.png);
  }
}
.lb-data {
  .lb-close {
    background-image: url(../img/close.png);
  }
}
// Addthis
.addthis_inline_share_toolbox {
  margin: 30px 0 10px 0;
}
.page-user {
  form {
    @extend .container;
    margin-top: 50px;
    margin-bottom: 150px;
  }
  .profile {
    @extend .container;
    margin-top: 50px;
    margin-bottom: 150px;
    text-align: center;
  }
}
.os-animation {
  opacity: 0;
  &.animated {
    opacity: 1;
  }
}
.page-search404 {
  main {
    padding-bottom: 200px;
    ul {
      @extend .container;
    }
  }
  .action-links {
    @extend .container;
  }
  form.search-form {
    @extend .container;
    input[type="submit"] {
      margin-top: 10px;
    }
  }
  .search-advanced {
    a {
      font-size: 1.4rem;
    }
  }
}
