// -------------------------------------------------------------
//   Navigation
// -------------------------------------------------------------

#secondary-nav {
  ul {
    margin: 0;
    padding: 10px 0 25px 0;
    display: flex;
    justify-content: flex-end;
    li {
      list-style: none;
      color: $brand-secondary;
      text-transform: uppercase;
      font-size: 0.9em;
      padding: 0;
      &:before {
        display: none;
      }
      &.bar {
        a {
          display: block;
          padding: 0 10px;
          color: $brand-secondary;
          text-transform: uppercase;
          &:after {
            content: '|';
            color: $brand-primary;
            margin-left: 10px;
          }
          &:hover {
            text-decoration: none;
          }
        }
      }
      p {
        font-size: 1em;
        margin: 0;
        padding: 0 20px 0 0;
        line-height: 1.2em;
        color: $brand-secondary;
        strong {
          color: $brand-primary;
          font-size: 1.2em;
        }
      }
      &.social {
        a {
          padding: 0 10px;
          i {
            color: $brand-primary;
            font-size: 1.5em;
          }
        }
      }
    }
  }
}

nav#main-nav {
  float: right;
  margin-top: 0;
  @include transition(all .2s ease-in-out);
  .showme_on_mobile {
    display: none
  }
  .menu {
    display: flex;
    justify-content: space-between;
    margin: 0;
    li {
      list-style: none;
      margin: 0;
      padding: 0;
      font-size: 1em;
      &:before {
        display: none;
      }
      a, .nolink {
        color: $brand-secondary;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 0.5px;
        font-size: 1em;
        display: block;
        padding: 10px;
        &.active {
          border-bottom: 2px solid $brand-primary;
          text-decoration: none;
        }
        &:hover {
          color: $brand-primary;
          text-decoration: none;
        }
      }
      &:last-child {
        padding-right: 0px;
      }
      &.expanded {
        position: relative;
        cursor: pointer;
        ul {
          display: block;
          opacity: 1;
          position: absolute;
          top: 40px;
          background: #FFF;
          z-index: -9999;
          text-align: center;
          float: none;
          width: 250px;
          padding: 0 0 5px 0px;
          box-shadow: 0px -2px 30px rgba(#000,0.2);
          @include transition(all 0.2s ease-in-out);
          transform: translateY(20px) translateX(-50%);
          left: 50%;
          opacity: 0;
          visibility: hidden;
          li {
            position: relative;
            text-align: center;
            width: 100%;
            float: none;
            padding-bottom: 0;
            a {
              color: rgba(#555,1);
              font-size: 15px;
              font-weight: 500;
              text-transform: none;
              letter-spacing: 0px;
              padding: 10px 10px;
              border-bottom: 1px solid $xlightgrey;
              margin: 0;
              position: relative;
              display: block;
              line-height: 140%;
              border-radius: 0;
              &.active {
                color: $brand-secondary;
                border-bottom: 1px solid $brand-secondary;
                text-decoration: none;
              }
              &:hover {
                color: $brand-primary;
                border-bottom: 1px solid $brand-primary;
                background: none;
              }
            }
            &.last {
              > a {
                border: 0;
                border-radius: 0;
              }
            }
          }
        }
        &.clicked {
          ul {
            transform: translateX(-50%) translateY(0px);
            left: 50%;
            margin: auto;
            opacity: 1;
            z-index: 9999;
            visibility: visible;
          }
        }
      }
      &.last {
        > a {
          background: #fff;
          color: $brand-primary;
          border: 1px solid $brand-primary;
          -moz-border-radius: 5px;
          -webkit-border-radius: 5px;
          border-radius: 5px;
          @include transition (all .2s);
          &:hover {
            background: $brand-primary;
            color: #fff;
          }
        }
      }
    }
  }
}
.mobile-menu {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  padding-bottom: 200px;
  background: rgba(255,255,255,1);
  z-index: 12;
  left: -999px;
  overflow-y: scroll;
  @include transition(left .5s ease-in-out);
  ul.menu {
    padding: 10px;
    display: block;
    li {
      display: block;
      list-style: none;
      &:before {
        display: none;
      }
      a, .nolink {
        display: block;
        padding: 10px;
        border-bottom: 1px solid $xlightgrey;
        color: $brand-secondary;
        &:hover, &:link, &:focus, &:active {
          color: #000;
        }
      }
      &.expanded {
        > a {
          position: relative;
          &:after {
            content: '\f067';
            font-family: FontAwesome;
            position: absolute;
            right: 10px;
            color: $brand-primary;
          }
        }
        ul.menu {
          display: none;
          padding: 0;
          background: $xlightgrey;
          @include transition (all .2s);
          li {
            a {
              border-bottom: 1px solid #fff;
            }
          }
        }
      }
      &.clicked {
        ul.menu {
          display: block;
        }
      }
    }
  }
  .other-links {
    ul {
      margin: 20px 0;
      padding: 0;
      display: flex;
      justify-content: center;
      li {
        list-style: none;
        width: 150px;
        &:before {
          display: none;
        }
        a {
          display: block;
          color: $brand-secondary;
          border: 1px solid $xlightgrey;
          padding: 10px;
          text-align: center;
          margin: 0 10px;
        }
      }
    }
  }
  .under-menu {
    text-align: center;
    p {
      margin-bottom: 20px;
    }
    strong {
      color: $brand-primary;
    }
    a {
      font-size: 30px;
      margin: 0 20px;
      &.phone-call {
        text-decoration: none;
        color: $brand-primary;
        display: block;
        text-align: center;
      }
    }
  }
}
body.open-menu {
  .mobile-menu {
    left: 0;
  }
}
.trigger-menu {
  width: 40px;
  height: 25px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  margin: 15px auto 0 auto;
  position: absolute;
  right: 30px;
  top: 20px;
  z-index: 10;
  span {
    display: block;
    position: absolute;
    height: 5px;
    width: 100%;
    background: $brand-primary;
    border-radius: 5px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    &:nth-child(1) {
      top: 0px;
    }
    &:nth-child(2) {
      top: 10px;
    }
    &:nth-child(3) {
      top: 20px;
    }
  }

  &.open {
    span {
      &:nth-child(1) {
        top: 10px;
        -webkit-transform: rotate(135deg);
        -moz-transform: rotate(135deg);
        -o-transform: rotate(135deg);
        transform: rotate(135deg);
      }
      &:nth-child(2) {
        opacity: 0;
        left: -60px;
      }
      &:nth-child(3) {
        top: 10px;
        -webkit-transform: rotate(-135deg);
        -moz-transform: rotate(-135deg);
        -o-transform: rotate(-135deg);
        transform: rotate(-135deg);
      }
    }
  }
}
