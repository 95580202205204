#categories {
  ul {
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin-bottom: 50px;
    li {
      list-style: none;
      text-align: center;
      min-width: 70px;
      &:before {
        display: none;
      }
      a {
          color: $brand-secondary;
          text-transform: uppercase;
          font-weight: 600;
          display: block;
          &:before {
            content: '';
            display: block;
            height: 80px;
            background-size: 70px 70px;
            background-repeat: no-repeat;
            background-position: center;
          }
          &:hover {
            color: $brand-primary;
            text-decoration: none;
          }
      }
      &.cat1 {
        a {
          &:before {
            background-image: url(../img/icon-cat1-grey.svg);
          }
          &:hover {
            &:before {
              background-image: url(../img/icon-cat1.svg);
            }
          }
        }
        &.active {
          a {
            color: $brand-primary;
            &:before {
              background-image: url(../img/icon-cat1.svg);
            }
          }
        }
      }
      &.cat2 {
        a {
          &:before {
            background-image: url(../img/icon-cat2-grey.svg);
          }
          &:hover {
            &:before {
              background-image: url(../img/icon-cat2.svg);
            }
          }
        }
        &.active {
          a {
            color: $brand-primary;
            &:before {
              background-image: url(../img/icon-cat2.svg);
            }
          }
        }
      }
      &.cat3 {
        a {
          &:before {
            background-image: url(../img/icon-cat3-grey.svg);
          }
          &:hover {
            &:before {
              background-image: url(../img/icon-cat3.svg);
            }
          }
        }
        &.active {
          a {
            color: $brand-primary;
            &:before {
              background-image: url(../img/icon-cat3.svg);
            }
          }
        }
      }
      &.cat4 {
        a {
          &:before {
            background-image: url(../img/icon-cat4-grey.svg);
          }
          &:hover {
            &:before {
              background-image: url(../img/icon-cat4.svg);
            }
          }
        }
        &.active {
          a {
            color: $brand-primary;
            &:before {
              background-image: url(../img/icon-cat4.svg);
            }
          }
        }
      }
      &.cat5 {
        a {
          &:before {
            background-image: url(../img/icon-cat5-grey.svg);
          }
          &:hover {
            &:before {
              background-image: url(../img/icon-cat5.svg);
            }
          }
        }
        &.active {
          a {
            color: $brand-primary;
            &:before {
              background-image: url(../img/icon-cat5.svg);
            }
          }
        }
      }
      &.cat6 {
        a {
          &:before {
            background-image: url(../img/icon-cat6-grey.svg);
          }
          &:hover {
            &:before {
              background-image: url(../img/icon-cat6.svg);
            }
          }
        }
        &.active {
          a {
            color: $brand-primary;
            &:before {
              background-image: url(../img/icon-cat6.svg);
            }
          }
        }
      }
      &.cat7 {
        a {
          &:before {
            background-image: url(../img/icon-cat7-grey.svg);
          }
          &:hover {
            &:before {
              background-image: url(../img/icon-cat7.svg);
            }
          }
        }
        &.active {
          a {
            color: $brand-primary;
            &:before {
              background-image: url(../img/icon-cat7.svg);
            }
          }
        }
      }
      &.cat8 {
        a {
          &:before {
            background-image: url(../img/icon-cat8-grey.svg);
          }
          &:hover {
            &:before {
              background-image: url(../img/icon-cat8.svg);
            }
          }
        }
        &.active {
          a {
            color: $brand-primary;
            &:before {
              background-image: url(../img/icon-cat8.svg);
            }
          }
        }
      }
    }
  }
}
.content {
  .description {
    padding: 0 50px 50px 50px;
    text-align: center;
  }
}
a.produit-list{
  display: block;
  height: 220px;
  color: #fff;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-bottom: 30px;
  .overlay {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background: rgba(0,0,0,.5);
    @include transition(height .2s);
  }
  .name {
    text-transform: uppercase;
    position: absolute;
    bottom: 10px;
    display: block;
    padding: 0 20px;
    width: 100%;
    text-align: center;
    font-weight: 600;
    font-size: 1.2em;
    z-index: 2;
    @include transition(bottom .2s);
  }
  &:hover {
    .overlay {
      height: 100%;
    }
    .name {
      bottom: 100px;
    }
  }
}
.produit-fiche {
  h3, .h3 {
    margin-top: 0;
  }
  ul.nav-tabs {
    padding: 20px 0 0 0;
    li {
      padding-bottom: 0;
      &:before {
        display: none;
      }
      a {
        text-transform: uppercase;
        color: $middlegrey;
        font-weight: 600;
        font-size: .9em;
        padding: 15px;
        border-radius: 0;
      }
    }
  }
  .tab-content {
    font-size: .9em;
    margin-top: 10px;
    line-height: 1.5em;
  }
}
#others {
  margin-top: 50px;
  h3 {
    text-align: center;
    padding-bottom: 20px;
  }
}
