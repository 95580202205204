section.view-nos-references {
	background: $brand-primary;
	padding-bottom: 150px;
	header {
		padding: 0 200px 50px 200px;
		text-align: center;
	}
	.view-filters {
		.views-exposed-widgets {
			display: flex;
			justify-content: center;
			margin-bottom: 50px;
			.views-exposed-widget {
				.form-type-select {
					width: 350px;
					margin: 0 10px;
					height: 45px;
					background: $brand-primary;
					border: 0;
					padding: 0 5px;
					-moz-border-radius: 5px;
					-webkit-border-radius: 5px;
					border-radius: 5px;
					overflow: hidden;
					position: relative;
					&:after {
						content: '\f078';
						font-family: FontAwesome;
						color: #fff;
						position: absolute;
						top: 12px;
						right: 10px;
					}
					select {
						background: transparent;
						width: 378px;
						font-size: .9em;
						line-height: 1em;
						border: 0;
						border-radius: 0;
						height: 45px;
						-webkit-appearance: none;
						text-transform: uppercase;
						font-weight: 600;
						color: #fff;
						option {
							color: #000;
						}	
					}
				}
				input[type="submit"] {
					&.form-submit {
						padding: 12px 20px;
					}
				}
			}
		}
	}
	.view-content {
		padding-bottom: 100px;
	}
}
.ref-text {
	font-size: 1em;
  	color: $middlegrey;
  	padding-bottom: 10px;
  	margin-bottom: 5px;
  	border-bottom: 1px solid $xlightgrey;
  	p {
  		font-size: 1em;
  	}
}