// -------------------------------------------------------------
//   Main footer
//   Footer styles
// -------------------------------------------------------------

footer.main-footer {
  background: $darkgrey;
  padding: 100px 0 40px 0;
  .footer-box {
    margin-top: -180px;
    border: 1px solid $xlightgrey;
    padding: 20px;
    background: #fff;
    text-align: center;
    height: 150px;
    h3 {
      font-size: 1em;
      text-transform: uppercase;
      margin-top: 0;
      margin-bottom: 20px;
    }
    &.social {
      a {
        padding: 0 10px;
        i {
          color: $brand-primary;
          font-size: 2em;
        }
      }
    }
    #mailchimp-newsletter-form {
      > div {
        display: flex;
        justify-content: center;
      }
      input[type="submit"] {
        padding: 0 10px;
        height: 50px;
        width: 100px;
        border-radius: 0;
        border: 1px solid $brand-primary;
        background: #fff;
        color: $brand-primary;
      }
    }
  }
  .address {
    font-size: 1em;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    border-left: 1px solid $lightgrey;
    padding-left: 30px;
    strong {
      color: $brand-primary;
    }
  }
  .logo {
    display: block;
    width: 250px;
    float: right;
  }
}
.copyright {
  text-align: center;
  padding: 20px 0;
  font-size: .8em;
  text-transform: uppercase;
  color: $darkgrey;
  a {
    color: $darkgrey;
    &:hover {
      color: #000;
    }
  }
}
