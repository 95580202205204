body.node-type-home {
  .main-title {
    display: none;
  }
}
#slider {
  position: relative;
  height: 600px;
  .swiper-pagination-bullets {
    margin: 0 auto;
    bottom: 100px;
    .swiper-pagination-buller {
      opacity: .4;
    }
    .swiper-pagination-bullet-active {
      background: #FFF;
    }
  }

  .swiper-slide {
    position: relative;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    .lebg {
      width: 100%;
      height: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      position: absolute;
      opacity: 0.5;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .overlay {
      position: absolute;
      height: 250px;
      width: 100%;
      top: 350px;
      left: 0;
      background: rgba(0,0,0,.5);
      padding-top: 20px;
      p {
        color: #fff;
        text-transform: uppercase;
        font-size: 1.5em;
        padding: 20px 0;
      }
      a.more {
        color: #fff;
        border: 1px solid #fff;
        background: transparent;
        padding: 10px 20px;
        text-transform: uppercase;
        font-size: 1em;
        -moz-border-radius: 5px;
        -webkit-border-radius: 5px;
        border-radius: 5px;
      }
    }
  }
  a.devis {
    position: relative;
    color: #fff;
    float: right;
    margin-top: 70px;
    padding: 10px 20px;
    background: $brand-primary;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    text-transform: uppercase;
    -webkit-box-shadow: 0px 3px 4px -2px rgba(107,107,107,1);
    -moz-box-shadow: 0px 3px 4px -2px rgba(107,107,107,1);
    box-shadow: 0px 3px 4px -2px rgba(107,107,107,1);
    letter-spacing: 2px;
    z-index: 11;
    @include transition (all .2s);
    &:hover {
      text-decoration: none;
      background: #fff;
      color: $brand-primary;
    }
  }
}
#intro {
  padding: 40px 0;
  h1 {
    color: $brand-secondary;
  }
}
.produits {
  padding-bottom: 100px;
  padding-top: 50px;
  background: $brand-primary;
  .container-produits {
    background: #fff;
    position: relative;
    z-index: 2;
    padding: 60px;
    ul {
      padding: 0;
      margin: 0;
      display: flex;
      flex-wrap: wrap;
      li {
        width: 25%;
        border: 1px solid $xlightgrey;
        margin: -1px 0 0 -1px;
        padding: 40px 20px;
        text-align: center;
        list-style: none;
        position:relative;
        &.cat1, &.cat5 {
          border-left: 0;
        }
        &.cat4, &.cat8 {
          border-right: 0;
        }
        .content-cat {
          position: relative;
          z-index: 3;
        }
        .pic {
          height: 60px;
          img {
            width: 70px;
          }
        }
        &.cat8 {
          .pic {
            img {
              width: auto;
              height: 50px;
            }
          }
        }
        .more {
          font-weight: bold;
          a {
            color: $brand-primary;
          }
        }
        p {
          font-size: 1em;
        }
        .inner-cat {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          z-index: 2;
          background: #fff;
          @include transition (all .2s);
        }
        &:hover {
          z-index: 5;
          .inner-cat {
            transform-origin: top -0.25% left -0.25%;
            transform: scale(1.25);
            -webkit-box-shadow: 0px 3px 15px -3px rgba(168,168,168,1);
            -moz-box-shadow: 0px 3px 15px -3px rgba(168,168,168,1);
            box-shadow: 0px 3px 15px -3px rgba(168,168,168,1);
            }
          }

      }
    }
  }
}
.rea-left {
  width: 50%;
  float: left;
  height: 440px;
  background-image: url('../img/sample-bg-realisations.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  position: relative;
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.7);
    z-index: 2;
  }
  h2 {
    position: relative;
    z-index: 3;
    color: #fff;
    margin-top: 190px;
  }
}
.rea-right {
  width: 50%;
  float: right;
}
.reference {
  width: 50%;
  float:left;
  a {
    display: block;
    height: 220px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    transform: scale(1);
    transition: transform 0.2s;
    -webkit-transition: -webkit-transform 0.2s;
    h3 {
      position: absolute;
      bottom: 20px;
      text-align: center;
      width: 100%;
      color: #fff;
      text-transform: uppercase;
      font-size: 1.2em;
      font-weight: 600;
      @include transition (all .2s);
    }
    &:hover {
      z-index: 10;
      -webkit-transform: scale(1.10);
      -moz-transform: scale(1.10);
      transform: scale(1.10);
    }
  }
  &.reference-link {
    a {
      background: $brand-primary;
      &:hover {
        h3 {
          bottom: 60px;
          &:before {
            content: '\f055';
            color: #fff;
            font-family: FontAwesome;
            font-size: 50px;
            display: block;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}
section.news {
  background-color: $brand-primary;
  padding-bottom: 40px;
  .news-title {
    background-color: #fff;
    padding: 50px 0 150px 0;
  }
  .news-home {
    margin-top: -100px;
  }
  .news-list {
    margin-top: 0;
    @include transition (all .2s);
    .pic {
      height: 200px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      margin-bottom: 20px;
    }
    .date {
      color: #fff;
      font-size: 1em;
      font-weight: 600;
      margin-bottom: 0;
    }
    h3 {
      margin: 0;
      padding: 0;
      font-size: 1.2em;
      letter-spacing: 2px;
      a {
        color: #fff;
      }
    }
    .description {
      color: #fff;
      font-size: .9em;
      line-height: 1.7em;
      padding-top: 20px;
      padding-bottom: 20px;
    }
    a.more {
      text-transform: uppercase;
      font-size: .9em;
      font-weight: 600;
      color: #fff;
    }
    &:hover {
      margin-top: -20px;
    }
  }
}
section.clients {
  padding: 60px 0 150px 0;
}
.clients-slider {
  width: 700px;
  position: relative;
  margin: 20px auto;
  .swiper-clients {
    width: 100%;
    overflow: hidden;
    .swiper-slide {
      width: 25%;
    }
  }
  .swiper-button-prev {
    background-image: none;
    left: - 100px;
    &:before {
      content: '\f053';
      font-family: FontAwesome;
      color: $lightgrey;
      font-size: 40px;
    }
  }
  .swiper-button-next {
    background-image: none;
    right: - 100px;
    &:after {
      content: '\f054';
      font-family: FontAwesome;
      color: $lightgrey;
      font-size: 40px;
    }
  }
}
