// -------------------------------------------------------------
//   Paragraphs item avantage
// -------------------------------------------------------------
.content-evidence {
	h3 {
		text-align: center;
		margin-bottom: 50px;
	}
	.inner-evidence {
		padding: 0 30px 0 0;
		border-right: 5px solid $brand-primary;
		margin: 20px 0;
		color: $middlegrey;
		font-size: 1.5em;
		font-style: italic;
		.img-left {
			float: left;
			margin-right: 30px;
		}
	}
}