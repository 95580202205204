// -------------------------------------------------------------
//   Paragraphs item texte
// -------------------------------------------------------------

.content-text {
	padding: 40px 0;
	font-size: 16px;
	color: $middlegrey;
	line-height: 30px;
	img {
		max-width: 100%;
    	height: auto !important;
	}
	.evidence {
		padding: 0 280px;
	}
	a {
		color: $brand-primary;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
}